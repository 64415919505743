@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

.about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 88.6vh;
  }

  .about-text {
    flex: 1 1 100%;
    margin: 20px 0;
    font-size: 60px;
  }

  .about-image-container {
    flex: 1 1 100%;
    margin: 20px 0;
  }

  .about-image {
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }

  .about-social-container {
    flex: 1 1 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }

  .about-social-icon {
    font-size: 36px;
    margin: 0 30px;
    color: #333;
  }

  .about-email-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .about-email {
    position: absolute;
    top: 790px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1px;
    font-size: 18px;
    color: #333;
    background: white;
    padding: 4px 8px;
    border-radius: 4px;
    z-index: -1;
  }

  @media only screen and (max-width: 480px) {
    .about-email {
      z-index: -1;
        margin-top: -200px;
    }
}
