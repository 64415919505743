@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

.background {
    height: 88.6vh;
    background-size: cover;
    display: flex;
  align-items: center;
  justify-content: center;
}



.btn, .btn1 {
    font-family: 'Titillium Web', sans-serif;
    text-decoration: none;
    color: #000;
    background-color: transparent;
    padding: 20px 40px;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 10px 20px;
    border: 4px solid #202020;
    transform: scale(1);
    transition: 0.20s ease-in-out;
    animation: bounce; 
  animation-duration: 2s;
  }
  
  .btn:hover, .btn1:hover {
     font-family: 'Titillium Web', sans-serif;
    background-color: rgb(39, 39, 39);
    color: rgb(255, 255, 255);
    cursor: pointer;
    transform: scale(1.1);
  box-shadow: 0px 0px 10px 2px #202020;
  }
