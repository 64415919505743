.project-grid {
    display: grid;
    margin-top: 2em;
    margin-left: 2em;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
}

a {
    width: 100px;
}

@media only screen and (max-width: 480px) {
    .project-grid img {
        width: 330px; /* or any other desired width */
    }
}
